<template>
<!--  max-width: 568px;-->
  <div class="d-flex justify-content-between main-menu w-100" style="padding-top: 20px;overflow-x: scroll;scrollbar-width: none;-ms-overflow-style: none; white-space: nowrap;">


    <!-- Home -->
    <router-link class="nav-item" to="/" v-bind:class="isActiveMenu('home')" >
      <!-- Image -->
      <div class="nav-icon">
        <img v-bind:src="getURL('/assets/images/home.svg')" v-bind:height="iconSize">
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important; text-align: center">Home</div>
      </div>
    </router-link>

    <!-- Live -->
    <router-link class="nav-item" to="/live" v-bind:class="isActiveMenu('live')" >
      <div class="nav-icon">
        <img v-bind:src="getURL('/assets/images/live_old.svg')" alt="qwerty live- best sports betting site" v-bind:height="iconSize">
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important; text-align: center">Live</div>
      </div>
    </router-link>


    <!-- Soccer -->
    <a class="nav-item" @click="setSport(1)" v-bind:class="getMenuTextClass(1)" >
      <div class="">
        <img alt="soccer" v-bind:src="getSportIcon(1)" v-bind:height="iconSize" onerror="this.src='/assets/sport-icons/1.svg'" />
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;text-align: center">Soccer</div>
      </div>
    </a>

    <!-- freebet -->
    <router-link v-show="show_freebet" class="nav-item" to="/freebet" v-bind:class="isActiveMenu('freebet')" >
      <div class="nav-icon">
        <img v-bind:src="getURL('/assets/images/freebet.svg')" alt="qwerty freebet- best sports betting site" v-bind:height="iconSize">
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;text-align: center">Freebet</div>
      </div>
    </router-link>

    <!-- Jackpot -->
    <router-link class="nav-item" to="/jackpot-games" v-bind:class="isActiveMenu('jackpot')" >
      <div class="nav-icon">
        <img v-bind:src="getURL('/assets/icons/jackpot-icon.svg')" v-bind:height="iconSize">
        <!--<span class="icon-new red">NEW</span>-->
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;text-align: center">Jackpot</div>
      </div>
    </router-link>

    <!-- E-sports -->
    <!--<router-link class="nav-item" to="/esport" v-bind:class="isActiveMenu('esport')" >
      <div class="nav-icon">
        <img src="/assets/images/esport.svg" alt="qwerty esport- best sports betting site" v-bind:height="iconSize">
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;text-align: center">eSports</div>
      </div>
    </router-link>-->

    <!-- <a v-for="(c,index) in sports" v-bind:key="getKey(index)" class="nav-item" @click="setSport(c.sport_id)" v-bind:class="getMenuTextClass(c.sport_id)" >
      <div class="nav-icon">

        <img v-bind:alt="c.sport_name" v-bind:src="getSportIcon(c.sport_id)" v-bind:height="iconSize" onerror="this.src='/assets/sport-icons/1.svg'" />
        <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;text-align: center">{{ c.sport_name }}</div>
      </div>
    </a> -->

  </div>

</template>

<style scoped>
  .icon-new.red {
    color: #fff;
    background-color: red;
  }
  .icon-new {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 8px;
    background: #fff30a;
    background-color: rgb(255, 243, 10);
    padding: 2px 4px;
    color: red;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 700;
    z-index: 702;
  }
</style>
<script>

export default {
  name: "Menu",
  methods: {

    getKey: function(index){

      return Math.random().toString(11).replace('0.', 'sport-'+index + '-');

    },
    setSport: function (sportID) {

        this.$store.dispatch("setSportID",sportID);

        if(this.$store.state.current_page === 'home')
          return

        this.$router.push({name: 'home'});
    },
    getMenuTextClass: function (sportID){

      return this.current_page === "esport" || this.current_page === "freebet" ? "" :  parseInt(this.$store.state.sport_id) === parseInt(sportID) ? "menu-active" : "";

    },
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
    resetGames: function(){

      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

        this.goHome();

      //}

    }
  },
  computed: {
    iconSize: function (){

      return 20;

    },
    current_page: function () {

      return this.$store.state.current_page
    },
    show_freebet: function (){

      var p = this.getProfile();
      if(!p) {

        return true;

      }

      return parseInt(p.b4) > 0;

    },
    sports: function() {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s,function(k,v){

        if(v.sport_id > 1 ) {

          t.push(v);
        }

      })

      return t;
    },
  },
}
</script>